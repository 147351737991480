import { apiMixin } from '../../../shared/mixins/apiMixin'
import { fileUtilsMixin } from '../../../shared/mixins/fileUtilsMixin'
import { getItem, postItem } from "@/api/api-methods";
import { mapState } from 'vuex'
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin'
import { saleUtilsMixing } from '../../mixins/saleUtilsMixin';

import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue'
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue'
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue'
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue'
import KnFormTitle from '../../../shared/components/KnFormTitle.vue'
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue'
import KnSelect from '../../../shared/components/KnSelect.vue'
import KnTextArea from '../../../shared/components/KnTextArea.vue'
import KnTextField from '../../../shared/components/KnTextField.vue'
import { BASE_CREATE_NEW_INVENTORY } from '../../helpers/states';
import { putItem } from '../../../../api/api-methods';
import { fetchGetInventory } from '../../helpers/inventoryUtils';
import { fetchGetPriceProduct } from '../../../products/helpers/productUtils';
import { paginationMixin } from '../../../shared/mixins/paginationMixin'
export default {
  components: {
    KnFormSubtitle,
    KnTextField,
    KnTextArea,
    KnSelect,
    KnLocalAlert,
    KnFormActionButtons,
    KnBackToTopButton,
    KnFormTitle,
    KnFormNoteOfMandatory,
  },
  mixins: [
    fileUtilsMixin,
    validationFormMixin,
    apiMixin,
    saleUtilsMixing,
    paginationMixin,
  ],
  data() {
    return {
      tabs: [
        { name: 'Información básica', value: 100 },
      ],
      valueDeterminate: 100,
      routerName: 'Inventario',
      productId: null,
      distributionPrice: null,
      inventoryId: null,
      inventory: {
        opcion_producto: null,
        almacen: null,
        cantidad_disponible: null,
        unidad_medida: null,
        comentarios: null,
        fecha_ultima_actualizacion: new Date(),
        autor: null,
        institucion_educativa: null,
        moneda: null
      },
      products: [],
      options: [],
      warehouses: [],
      coins: [],
      measurementUnits: [],
      movementTypes: [],
      movementReasons: [],
      amountToAdd: null,
      loadingPrice: false,
      error: null,
      coin: null,
      loadingPage: false,
      loadingProducts: false,

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse']),
    title() {
      return this.inventoryId === null ? 'Agregar inventario' : 'Actualizar inventario'
    },
    isEditMode() {
      return this.inventoryId !== null
    },
    successAlertText() {
      return this.isEditMode ? 'Producto actualizado con exito!' : 'Producto registrado con exito!'
    },
    successAlertType() {
      return this.isEditMode ? 'info' : 'success'
    },
    amountToAddValid() {
      return this.amountToAdd !== null && this.amountToAdd > 0
    }
  },
  async created() {
    this.loadingPage = true
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      await this.loadDataInventory()
      if (this.$route.params.id) {
        this.inventoryId = this.validateIdRoute(this.$route.params.id);
        await this.constructorInventory()
      }
    } catch (error) {
      console.error('Error in created:', error);
      this.error = "Error al cargar datos. ";
      this.setErrorState('Error al cargar datos. Por favor, inténtelo de nuevo.')
    } finally {
      this.loadingPage = false
      this.setLoadingState(false)
    }
  },
  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = this.successAlertType;
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion

    validateIdRoute(id) {
      if (!/^\d+$/.test(id)) {
        this.error = "Formato de ID no válido.";
        throw new Error('Formato de ID no válido');
      }
      return id;
    },

    async loadDataInventory() {
      const [
        measurementUnitsRes,
        coinsRes,
        optionsRes,
        warehousesRes,
      ] = await Promise.all([
        // getItem(`/app-productos/filters/product?institucion_educativa=${this.institutionId}&estatus_sistema=true`),
        getItem(`/app-productos/filters/uom?institucion_educativa=${this.institutionId}&estatus_sistema=true`),
        getItem(`/app-administracion/filters/moneda?institucion_educativa=${this.institutionId}&estatus_sistema=true`),
        getItem(`/app-productos/filters/tipo-opcion-producto?institucion_educativa=${this.institutionId}&estatus_sistema=true`),
        getItem(`/app-inventarios/filters/almacen?institucion_educativa=${this.institutionId}&estatus_sistema=true`),
        this.loadProducts(),
      ]);

      // this.products = productsRes.results;
      this.measurementUnits = measurementUnitsRes.results;
      this.coins = coinsRes.results;
      this.options = optionsRes.results
      this.warehouses = warehousesRes.results
    },

    async constructorProductPrice(id) {
      this.loadingPrice = true
      try {
        const price = await fetchGetPriceProduct(id)
        this.distributionPrice = parseFloat(price.precio_distribuidor).toFixed(2)
        this.coin = price.moneda.id
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPrice = false
      }
    },

    async loadProducts() {
      this.loadingProducts = true;
      try {
        const { limit, offset } = this.pagination;
        const limitFilter = limit !== null ? `&limit=${limit}` : '';
        const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';

        const response = await getItem(`/app-productos/filters/product?institucion_educativa=${this.institutionId}&estatus_sistema=true${limitFilter}${offsetFilter}`);
        if (response && response.results) {
          this.products = [...this.products, ...response.results];
          this.setPaginationCount(response.count);
        }
      } catch (error) {
        console.error('Error loading SAT codes:', error);
      } finally {
        this.loadingProducts = false;
      }
    },

    async constructorInventory() {
      try {
        const { res } = await fetchGetInventory(this.inventoryId)
        this.productId = res.producto.id
        await this.constructorProductPrice(this.productId)
        this.inventory = {
          ...res,
          almacen: res.almacen.id,
        }
        // console.log({ res });
      } catch (error) {
        this.error = "Error al cargar datos de inventario ";
        console.error('Error in constructorInventory:', error);
      }
    },

    setTabValue(val) {
      this.valueDeterminate = val
    },

    async endIntersect() {
      this.setPaginationPage(this.pagination.nextPage);
      await new Promise(resolve => setTimeout(resolve, 500));
      await this.loadProducts()
    },

    async createInventory() {
      try {
        const responseData = await postItem('/app-inventarios/inventario', {
          producto: this.productId,
          opcion_producto: this.inventory.opcion_producto,
          almacen: this.inventory.almacen,
          cantidad_disponible: this.inventory.cantidad_disponible,
          unidad_medida: this.inventory.unidad_medida,
          comentarios: this.inventory.comentarios || '',
          fecha_ultima_actualizacion: this.inventory.fecha_ultima_actualizacion,
          autor: this.userData.id,
          institucion_educativa: this.institutionId
        })
        if (responseData) {
          this.alertText = 'Inventario creado con exito'
        } else {
          this.warnings.push('No se pudo crear inventario')
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async updateInventory() {
      try {
        // console.log(this.inventory);
        const response = await putItem(`/app-inventarios/inventario/${this.inventory.id}`, {
          id: this.inventory.id,
          producto: this.productId,
          opcion_producto: this.inventory.opcion_producto.id,
          almacen: this.inventory.almacen,
          cantidad_disponible: this.inventory.cantidad_disponible + this.amountToAdd,
          unidad_medida: this.inventory.unidad_medida.id,
          comentarios: this.inventory.comentarios,
          fecha_ultima_actualizacion: new Date(),
          autor: this.userData.id,
          institucion_educativa: this.institutionId
        })
        if (response) {
          this.alertText = 'Inventario actualizado con exito'
        } else {
          this.errors.push('No se pudo actualizar inventario')
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async createInventoryMovement() {
      try {

        const movementType = await this.getOrCreateMovementType(BASE_CREATE_NEW_INVENTORY.MOVEMENT_TYPE, this.userData.id)
        const movementReason = await this.getOrCreateMovementReason(BASE_CREATE_NEW_INVENTORY.MOVEMENT_REASON, this.userData.id)

        const initialAmount = this.inventory.cantidad_disponible
        const amountOfMovement = this.isEditMode ? this.amountToAdd : this.inventory.cantidad_disponible
        const finalAmount = initialAmount + amountOfMovement
        const unitPrice = this.distributionPrice
        const valueOfMovement = unitPrice * amountOfMovement

        const movementObj = {
          producto: this.productId,
          tipo_movimiento: movementType.id, // Tipo de movimiento: entrada
          motivo_movimiento: movementReason.id, // Motivo de movimiento: compra
          almacen: this.inventory.almacen,
          inventario_inicial: initialAmount,
          cantidad_movimiento: amountOfMovement,
          inventario_final: finalAmount,
          precio_unitario: unitPrice,
          valor_movimiento: valueOfMovement,
          moneda: this.coin,
          autor: this.userData.id,
          institucion_educativa: this.institutionId
        }

        const responseData = await postItem('/app-inventarios/movimiento-inventario', movementObj)

        if (responseData) {
          this.alertText = 'Movimiento de inventario creado con exito'
        } else {
          this.errors.push('No se pudo crear movimiento de inventario.')
        }
      } catch (error) {
        console.error('Error al intentar crear movimiento de inventario', error)
        this.errors.push('Error al intentar crear movimiento de inventario')
      }
    },
    async save() {
      this.setLoadingState(true, 'Guardando cambios en el inventario', 'info');
      try {
        if (this.inventoryId) {
          await Promise.all([
            this.updateInventory(),
            this.createInventoryMovement(),
          ])
        } else {
          await Promise.all([
            this.createInventory(),
            this.createInventoryMovement(),
          ])
        }
        this.setSuccessState('Cambios en el inventario guardados exitosamente');
      } catch (error) {
        // console.log(error);
        this.setErrorState('Error al guardar cambios en el inventario');
      }
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName })
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
  }
}